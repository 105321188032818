import { defineStore } from 'pinia'
import { getViewPermission } from '@/api'

export type PermissionState = {
  componentIdentifier: string
  componentName: string
  isDisplay: 1 | 2
  isDisabled: 1 | 2
}
export const permissionStore = defineStore(
  'permissionStore',
  () => {
    const permissionList = ref<PermissionState[]>([])
    const identifierList = computed(() => {
      return permissionList.value.map((item) => item.componentIdentifier)
    })
    // 重置
    const resetPermissions = () => {
      permissionList.value = []
    }
    const getViewPermissions = async () => {
      const { data } = await getViewPermission()
      permissionList.value = data || []
    }
    return {
      permissionList,
      identifierList,
      resetPermissions,
      getViewPermissions,
    }
  },
  {
    persist: [
      {
        key: '_appPermission',
        storage: sessionStorage,
        paths: ['permissionList', 'identifierList'],
      },
    ],
  },
)
