import { CircleUserRound, ShieldCheck } from 'lucide-vue-next'
import { Card, CardContent, CardFooter, CardHeader, CardTitle } from '@/components/ui/card'
import autoAnimate from '@formkit/auto-animate'
import { createReusableTemplate } from '@vueuse/core'
import { UAParser } from 'ua-parser-js'
import { Button } from '@/components/ui/button'
import Avater from '@/assets/avater.png'
import { cn } from '@/utils'
import { FormInst, FormItemRule } from 'naive-ui'
import { updatePassword } from '@/api'
export const UserProfile = defineComponent({
  name: 'UserProfile',
  props: {
    user: {
      type: Object,
      default: () => ({}),
    },
  },
  setup() {
    const userstore = userStore()
    const router = useRouter()
    const activeId = ref(0)
    const handleClick = (id: number) => {
      activeId.value = id
    }
    /**
     * @description 修改密码
     */
    const isEdit = ref(false)
    const dropdown = ref<HTMLDivElement>()

    watch(
      () => activeId.value,
      (val) => {
        if (val === 1) {
          nextTick(() => {
            dropdown.value && autoAnimate(dropdown.value)
          })
        }
      },
    )

    const logoOut = () => {
      userstore.resetState()
      // 跳转首页,并重载页面
      router.push({
        path: '/redirect/',
      })
    }

    const parser = new UAParser(navigator.userAgent)
    const os = computed(() => {
      const { os } = parser.getResult()
      return os.name
    })
    const brower = computed(() => {
      const { browser } = parser.getResult()
      return browser.name + ' ' + browser.version
    })

    const [DefineTemplate, ReuseTemplate] = createReusableTemplate()
    const formRef = ref<FormInst | null>(null)
    function validatePasswordStartWith(_rule: FormItemRule, value: string): boolean {
      return (
        !!formValue.password &&
        formValue.password.startsWith(value) &&
        formValue.password.length >= value.length
      )
    }
    function validatePasswordSame(_rule: FormItemRule, value: string): boolean {
      return value === formValue.password
    }
    const formValue = reactive({
      oldPassword: '',
      password: '',
      password1: '',
    })
    const rules = {
      oldPassword: {
        required: true,
        message: '请输入旧密码',
        trigger: 'blur',
      },
      password: {
        required: true,
        message: '请输入密码',
        trigger: ['input', 'blur'],
      },
      password1: [
        {
          required: true,
          message: '请再次输入密码',
          trigger: ['input', 'blur'],
        },
        {
          validator: validatePasswordStartWith,
          message: '两次密码输入不一致',
          trigger: 'input',
        },
        {
          validator: validatePasswordSame,
          message: '两次密码输入不一致',
          trigger: ['blur', 'password-input'],
        },
      ],
    }
    const handleValidateClick = () => {
      formRef.value?.validate(async (errors: any) => {
        if (!errors) {
          const { oldPassword, password } = formValue
          const res = await updatePassword({ oldPassword, password })
          if (res.code === 1) {
            isEdit.value = false
            window.$message?.success('更新成功, 请重新登录')
            logoOut()
          } else {
            window.$message?.error(res.msg)
          }
        }
      })
    }
    return () => (
      <>
        <DefineTemplate>
          <Card class='w-[350px]'>
            <CardHeader>
              <CardTitle class='text-base font-bold'>更新密码</CardTitle>
            </CardHeader>
            <CardContent>
              <n-form ref={formRef} model={formValue} rules={rules}>
                <n-form-item label='旧密码' path='oldPassword'>
                  <n-input
                    v-model:value={formValue.oldPassword}
                    type='password'
                    placeholder='输入密码'
                  />
                </n-form-item>
                <n-form-item label='新密码' path='password'>
                  <n-input
                    v-model:value={formValue.password}
                    type='password'
                    placeholder='输入密码'
                  />
                </n-form-item>
                <n-form-item label='确认密码' path='password1'>
                  <n-input
                    v-model:value={formValue.password1}
                    type='password'
                    placeholder='输入密码'
                  />
                </n-form-item>
              </n-form>
            </CardContent>
            <CardFooter class='flex justify-end gap-2 px-6 pb-6'>
              <Button
                class='px-2 py-1 h-auto'
                variant='outline'
                onClick={() => (isEdit.value = false)}>
                取消
              </Button>
              <Button class='px-2 py-1 h-auto' onClick={handleValidateClick}>
                确认
              </Button>
            </CardFooter>
          </Card>
        </DefineTemplate>
        <div class='lg:h-[60vh] h-[90vh] flex items-start'>
          <div class='w-52 h-full bg-neutral-100 dark:bg-neutral-900  border-r flex flex-col gap-2 py-5 px-3'>
            <div class='px-3 mb-4'>
              <h4 class='text-base lg:text-xl font-bold tracking-wide'>账号</h4>
              <p>管理你的账户信息.</p>
            </div>
            <div
              class={cn(
                'flex items-center gap-2 py-1 px-3 rounded cursor-pointer',
                activeId.value === 0 && ' bg-neutral-200 dark:bg-neutral-700',
              )}
              onClick={() => handleClick(0)}>
              <CircleUserRound class='w-4' />
              <span>基本信息</span>
            </div>
            <div
              class={cn(
                'flex items-center gap-2 py-1 px-3 rounded cursor-pointer',
                activeId.value === 1 && ' bg-neutral-200 dark:bg-neutral-700',
              )}
              onClick={() => handleClick(1)}>
              <ShieldCheck class='w-4' />
              <span>安全</span>
            </div>
          </div>
          <div class='flex-1 p-7 h-full overflow-auto'>
            {activeId.value === 0 ? (
              <>
                <div class='text-base lg:text-lg font-bold tracking-wide mb-4'>个人资料详细</div>
                <div class='flex gap-2 items-center border-t py-4'>
                  <div class='w-52'>信息</div>
                  <div class='flex flex-1 items-center gap-2'>
                    <n-avatar
                      round
                      size='large'
                      src={Avater}
                      fallback-src='https://07akioni.oss-cn-beijing.aliyuncs.com/07akioni.jpeg'
                    />
                    <div class={'ml-2'}>{userstore.userInfo?.userName}</div>
                  </div>
                </div>
                <div class='flex gap-2 items-center border-t py-4'>
                  <div class='w-52'>所属部门</div>
                  <div
                    class={cn('flex flex-1 gap-2', isEdit.value ? 'items-start' : 'items-center')}>
                    {userstore.userInfo?.department}
                  </div>
                </div>
              </>
            ) : (
              <>
                <div class='text-base lg:text-lg font-bold tracking-wide mb-4'>安全相关</div>
                <div class='flex gap-2 items-start border-t py-4'>
                  <div class='w-52'>密码</div>
                  <div class='flex flex-1 items-center gap-2' ref={dropdown}>
                    {!isEdit.value ? (
                      <>
                        <div class='flex flex-1 items-center gap-2'>
                          <p>*********** {isEdit.value}</p>
                        </div>
                        <Button variant='ghost' onClick={() => (isEdit.value = !isEdit.value)}>
                          修改密码
                        </Button>
                      </>
                    ) : (
                      <ReuseTemplate />
                    )}
                  </div>
                </div>
                <div class='flex gap-2 items-center border-t py-4'>
                  <div class='w-52'>当前设备</div>
                  <div class='text-sm flex-1'>
                    <p>
                      {os.value}{' '}
                      <span class='text-neutral-500 text-xs leading-5 p-1 rounded bg-neutral-200 dark:bg-neutral-700'>
                        当前设备
                      </span>
                    </p>
                    <p>{brower.value}</p>
                  </div>
                </div>
                <div class='flex gap-2 items-center border-t py-4'>
                  <div class='w-52'>退出账号</div>
                  <div class='flex flex-1 items-center gap-2' onClick={logoOut}>
                    <Button variant='ghost' class='text-red-600'>
                      退出
                    </Button>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </>
    )
  },
})
