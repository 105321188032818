import { UploadFileInfo } from 'naive-ui'
import { defineStore } from 'pinia'

/**
 * @description 知识库上传文件
 */
export const uploadStore = defineStore('upload', () => {
  const filesList: Ref<UploadFileInfo[]> = ref([])
  return {
    filesList,
  }
})
