import { defineStore } from 'pinia'
import { darkTheme } from 'naive-ui'
import { usePreferredColorScheme } from '@vueuse/core'
import { BuiltInGlobalTheme } from 'naive-ui/es/themes/interface'

export type TMode = 'light' | 'dark' | 'auto'
export const themeStore = defineStore(
  'themeStore',
  () => {
    const preferredColor = usePreferredColorScheme()

    /**
     * @description 聊天时的字体大小
     */
    const chatSize = ref(16)

    /**
     * @description 意图唤醒界面时，是否自动打开链接
     */
    const autoOpenLink = ref(false)

    /**
     * @description 聊天窗口模式，会话，文档
     */
    const chatMode = ref<ChatMode>('chat')
    /**
     * @description 设置chatMode
     */
    const setChatMode = (str: ChatMode) => {
      chatMode.value = str
    }

    /**
     * @description 限制会话历史记录存储条数
     */
    const isHistoryLimit = ref(false)
    const chatHistoryLimit = ref(100)

    /**
     * @description 主题模式
     */
    const themeMode = ref<TMode>('light')
    /**
     * @description 是否暗黑模式
     */
    const isDark = computed<boolean>(() => {
      if (themeMode.value === 'auto') {
        return preferredColor.value === 'dark'
      }
      return themeMode.value === 'dark'
    })
    /**
     * @description naive-ui主题切换
     */
    const theme = computed<BuiltInGlobalTheme | null>(() => {
      isDark.value ? document.body.classList.add('dark') : document.body.classList.remove('dark')
      return isDark.value ? darkTheme : null
    })

    // 点击切换主题
    const switchTheme = () => {
      if (isDark.value) {
        themeMode.value = 'light'
        document.body.classList.remove('dark')
      } else {
        themeMode.value = 'dark'
        document.body.classList.add('dark')
      }
    }

    const setTheme = (str: TMode) => {
      themeMode.value = str
      if (themeMode.value === 'light') {
        document.body.classList.remove('dark')
      } else if (themeMode.value === 'dark') {
        document.body.classList.add('dark')
      }
    }
    return {
      theme,
      isDark,
      themeMode,
      chatSize,
      autoOpenLink,
      chatMode,
      setChatMode,
      switchTheme,
      setTheme,
      isHistoryLimit,
      chatHistoryLimit,
    }
  },
  {
    persist: {
      key: '_appThemeSetting',
      storage: localStorage,
      paths: [
        'isDark',
        'themeMode',
        'chatSize',
        'autoOpenLink',
        'chatMode',
        'isHistoryLimit',
        'chatHistoryLimit',
      ],
    },
  },
)
