import { PropType, defineComponent, ref } from 'vue'
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from '@/components/ui/dialog'
import {
  Drawer,
  DrawerContent,
  DrawerDescription,
  DrawerHeader,
  DrawerTitle,
  DrawerTrigger,
} from '@/components/ui/drawer'
import { createReusableTemplate } from '@vueuse/core'
import { useResponsive } from '@/hooks/useResponsive'
import { Login } from '@/components/Login'
export const LoginDialog = defineComponent({
  name: 'LoginDialog',
  props: {
    open: {
      type: Boolean,
      default: false,
    },
    success: {
      type: Function as PropType<(data: any) => void>,
      required: true,
    },
    close: {
      type: Function,
      required: true,
    },
  },
  emits: ['update:open'],
  setup(props, { emit }) {
    const { isMobile } = useResponsive()
    const show = ref(props.open)
    /**
     * @description 离开路由时，如果弹窗打开，则阻止离开，先关闭弹窗
     */
    onBeforeRouteLeave(() => {
      if (!show.value) {
        return true
      }
      show.value = false
      return false
    })
    watch(show, (val) => {
      emit('update:open', val)
      !val && props?.close()
    })
    const success = (data: any) => {
      show.value = false
      props?.success(data)
    }
    const [DefineTemplate, ReuseTemplate] = createReusableTemplate()
    return () => (
      <>
        <DefineTemplate>
          <Login onSuccess={(data) => success(data)}></Login>
        </DefineTemplate>
        {isMobile.value ? (
          <Drawer v-model:open={show.value}>
            <DrawerTrigger class='text-blue-500 max-w-32 truncate'> </DrawerTrigger>
            <DrawerContent>
              <DrawerHeader>
                <DrawerTitle>用户登录</DrawerTitle>
              </DrawerHeader>
              <DrawerDescription class='px-2 pb-3'> 登录应用,获得完整的体验 </DrawerDescription>
              <div class='h-[40vh] overflow-y-auto px-6 mt-6'>
                <ReuseTemplate />
              </div>
            </DrawerContent>
          </Drawer>
        ) : (
          <Dialog v-model:open={show.value} modal={true}>
            <DialogContent class='sm:max-w-[425px]'>
              <DialogHeader>
                <DialogTitle>用户登录</DialogTitle>
                <DialogDescription> 登录应用,获得完整的体验 </DialogDescription>
              </DialogHeader>
              <ReuseTemplate />
            </DialogContent>
          </Dialog>
        )}
      </>
    )
  },
})
