import type {
  AxiosHeaders,
  AxiosProgressEvent,
  AxiosResponse,
  GenericAbortSignal,
  AxiosRequestConfig,
} from 'axios'
import request from './axios'

export interface HttpOption extends AxiosRequestConfig {
  url: string
  data?: unknown
  method?: string
  headers?: AxiosHeaders
  onDownloadProgress?: (progressEvent: AxiosProgressEvent) => void
  onUploadProgress?: (progressEvent: AxiosProgressEvent) => void
  signal?: GenericAbortSignal
  beforeRequest?: () => void
  afterRequest?: () => void
}

export interface Response<T = unknown> {
  data: T
  message: string | null
  status?: string
  code?: string | number
  [key: string]: any
}

function http<T = unknown>(options: HttpOption) {
  const {
    url,
    data,
    method = 'GET',
    headers,
    onDownloadProgress,
    onUploadProgress,
    signal,
    beforeRequest,
    afterRequest,
    responseType,
  } = options
  const successHandler = (res: AxiosResponse<Response<T>>) => {
    if (res.data.status === 'Success' || typeof res.data === 'string') {
      return res.data
    }

    if (res.data.status === 'Unauthorized') {
      window.location.reload()
    }

    return Promise.resolve(res.data)
  }

  const failHandler = (error: Response<Error>) => {
    afterRequest?.()
    throw new Error(error?.message || 'Error')
  }

  beforeRequest?.()

  const params = Object.assign(typeof data === 'function' ? data() : (data ?? {}), {})

  return method === 'GET'
    ? request
        .get(url, { params, headers, signal, onDownloadProgress, responseType })
        .then(successHandler, failHandler)
    : request
        .post(url, params, { headers, signal, onDownloadProgress, onUploadProgress, responseType })
        .then(successHandler, failHandler)
}

export function get<T = unknown>({
  url,
  data,
  method = 'GET',
  headers,
  onDownloadProgress,
  signal,
  beforeRequest,
  afterRequest,
}: HttpOption): Promise<Response<T>> {
  return http<T>({
    url,
    method,
    data,
    headers,
    onDownloadProgress,
    signal,
    beforeRequest,
    afterRequest,
  })
}

export function post<T = unknown>({
  url,
  data,
  method = 'POST',
  headers,
  onDownloadProgress,
  onUploadProgress,
  signal,
  beforeRequest,
  afterRequest,
}: HttpOption): Promise<Response<T>> {
  return http<T>({
    url,
    method,
    data,
    headers,
    onDownloadProgress,
    onUploadProgress,
    signal,
    beforeRequest,
    afterRequest,
  })
}
export async function downloadFile(
  { url, params, method = 'GET', headers }: HttpOption,
  fileName: string,
) {
  const res = await request({
    url,
    method,
    params,
    headers,
    responseType: 'blob',
  })
  const blob: Blob = new Blob([res.data as unknown as BlobPart])
  const link: HTMLAnchorElement = document.createElement('a')
  link.href = URL.createObjectURL(blob)
  link.download = fileName
  document.body.appendChild(link)
  link.click()
  URL.revokeObjectURL(link.href)
  link.remove()
}
export default post
