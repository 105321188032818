import { DirectiveBinding } from 'vue'
type Fn = (e?: MouseEvent) => void
// 逻辑封装
const customClick = (binding: DirectiveBinding<Fn>) => {
  return () => {
    binding.value()
  }
}

export const Click = {
  created(el: HTMLElement, binding: DirectiveBinding<Fn>) {
    el.addEventListener('click', customClick(binding))
  },
  unmounted(el: HTMLElement, binding: DirectiveBinding<Fn>) {
    el.removeEventListener('click', customClick(binding))
  },
}
