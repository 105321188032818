import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '@/components/ui/tooltip'
export const Hover = defineComponent({
  name: 'Hover',
  props: {
    class: {
      type: String,
      default: '',
    },
    content: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    border: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String as PropType<'small' | 'normal'>,
      default: 'normal',
    },
  },
  emits: ['click'],

  setup(props, { slots, emit }) {
    const sizes = {
      small: 'lg:p-1',
      normal: 'lg:p-2',
    }
    return () => (
      <TooltipProvider>
        <Tooltip>
          <TooltipTrigger asChild>
            <div
              onClick={(e) => {
                e.stopPropagation()
                e.preventDefault()
                emit('click', e)
              }}
              class={[
                `text-sm text-gray-500
                transition duration-500 ease
                p-1 rounded-md
                text-center select-none
                dark:border-theme-600`,
                `${sizes[props.size]}`,
                ` ${props.class}`,
                props.border && 'border',
                !props.disabled && 'hover:text-theme-600 hover:bg-theme-200 ',
                !props.disabled && 'dark:hover:text-theme-50 dark:hover:bg-theme-900',
                props.disabled
                  ? 'cursor-not-allowed text-neutral-300 dark:text-neutral-800 pointer-events-none'
                  : 'cursor-pointer',
              ]}>
              <div class={props.disabled && 'pointer-events-none'}>{slots.default?.()}</div>
            </div>
          </TooltipTrigger>
          {props.content && <TooltipContent class={'max-w-52'}>{props.content}</TooltipContent>}
        </Tooltip>
      </TooltipProvider>
    )
  },
})
