import logo from '@/assets/logo.png'
export const FallBack = () => {
  setTimeout(() => {
    sessionStorage.setItem('_isloaded', '1')
  }, 2000)
  return (
    <>
      {!sessionStorage.getItem('_isloaded') ? (
        <div class='flex h-screen w-screen items-center justify-center'>
          <div class='flex flex-col items-center justify-center'>
            <div class='text-center text-2xl font-bold flex items-center gap-3'>
              <img src={logo} alt='logo' class='h-10 w-10' />
              {window.VITE_TITLE ? window.VITE_TITLE : '博闻智星'}
            </div>
            <div class='mt-4 text-center text-sm text-gray-500'>应用加载中, 请耐心等待...</div>
          </div>
        </div>
      ) : null}
    </>
  )
}
