if (!Array.prototype.at) {
  Array.prototype.at = function (index) {
    // 计算实际索引，处理负值的情况
    const idx = index < 0 ? this.length + index : index

    // 检查索引是否在合法范围内
    if (idx >= 0 && idx < this.length) {
      return this[idx]
    }
    return undefined // 或者根据需要抛出错误
  }
}

if (!Array.prototype.findLastIndex) {
  Array.prototype.findLastIndex = function (
    predicate: (value: any, index: number, obj: any[]) => boolean,
  ): number {
    for (let i = this.length - 1; i >= 0; i--) {
      if (predicate(this[i], i, this)) {
        return i
      }
    }
    return -1
  }
}
