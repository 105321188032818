import { chatStore } from '@/store'
/**
 * @description 刷新当前订阅得技能
 */
export const useRefreshAgent = () => {
  const chatstore = chatStore()

  function showNotificationFn() {
    const options = {
      injectFileBase: '',
      checkInterval: 10 * 60 * 1000,
      hiddenDefaultNotification: false,
      checkOnWindowFocus: true,
      checkImmediately: true,
      checkOnLoadFileError: true,
    }
    document.body.dispatchEvent(
      new CustomEvent('plugin_web_update_notice', {
        detail: {
          options,
          version: '4eebed11',
        },
        bubbles: true,
      }),
    )
    showNotification(options)
  }

  function showNotification(options: any) {
    try {
      const { notificationConfig, customNotificationHTML } = options

      const notificationWrap = document.createElement('div')
      let notificationInnerHTML = ''

      if (customNotificationHTML) {
        notificationInnerHTML = customNotificationHTML
      } else {
        const { primaryColor } = notificationConfig || {}
        const title = '权限变更提醒！'
        const description = '权限变更！请刷新页面后使用。'
        const buttonText = '刷新'

        notificationWrap.classList.add('plugin-web-update-notice')
        notificationWrap.style.cssText = 'bottom: 24px;right: 24px'
        notificationInnerHTML = `
    <div class="plugin-web-update-notice-content" data-cy="notification-content">
      <div class="plugin-web-update-notice-content-title">
        ${title}
      </div>
      <div class="plugin-web-update-notice-content-desc">
        ${description}
      </div>
       <div class="plugin-web-update-notice-tools">
        <a class="plugin-web-update-notice-btn plugin-web-update-notice-refresh-btn" style="color:${primaryColor}">
          ${buttonText}
        </a>
      </div>
    </div>`
      }

      notificationWrap.innerHTML = notificationInnerHTML
      document.querySelector(`.plugin-web-update-notice-anchor`)?.appendChild(notificationWrap)

      const refreshBtn = document.querySelectorAll(`.plugin-web-update-notice-refresh-btn`)
      refreshBtn?.forEach((btn) => {
        btn.addEventListener('click', async () => {
          await chatstore.fetchDefaultAgent()
          window.location.reload()
        })
      })
    } catch (err) {
      console.error('[pluginWebUpdateNotice] Failed to show notification', err)
    }
  }

  return { showNotificationFn }
}
