export default (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='24'
    height='24'
    viewBox='0 0 24 24'
    fill='transparent'
    stroke='currentColor'
    stroke-width='2'
    stroke-linecap='round'
    stroke-linejoin='round'>
    <path d='M10.283 21.12a.53.53 0 0 1-.378-.15.7.7 0 0 1-.202-.386q-.21-1.415-.448-2.373-.237-.958-.615-1.573a2.9 2.9 0 0 0-.985-.985q-.606-.369-1.555-.597a23 23 0 0 0-2.33-.413.63.63 0 0 1-.404-.194.57.57 0 0 1-.15-.395q0-.229.15-.396a.63.63 0 0 1 .405-.193q1.388-.15 2.337-.36.959-.22 1.574-.598.614-.378.993-1.002.378-.624.606-1.59.238-.976.422-2.392a.6.6 0 0 1 .193-.377q.167-.15.387-.15.229 0 .387.15.158.14.202.377a25 25 0 0 0 .44 2.382q.237.967.606 1.591.378.624.993 1.002.615.369 1.556.589.95.21 2.338.378.246.034.395.202.15.158.15.387a.56.56 0 0 1-.159.395.6.6 0 0 1-.386.194q-1.389.158-2.347.377-.95.212-1.564.58-.616.37-.994 1.002-.378.625-.606 1.591a26 26 0 0 0-.422 2.391.7.7 0 0 1-.202.387q-.15.15-.387.15m-6.17-9.765q-.316 0-.351-.342a12 12 0 0 0-.229-1.354q-.114-.501-.369-.747-.255-.255-.765-.378a15 15 0 0 0-1.388-.272q-.36-.053-.36-.36 0-.309.316-.361.905-.166 1.415-.299.519-.141.773-.378.264-.246.378-.73.123-.483.229-1.353.034-.342.351-.343.325 0 .36.334.115.888.23 1.39.122.5.377.755t.765.378q.51.123 1.415.246.141.018.228.114a.33.33 0 0 1 .097.246q0 .3-.316.36a18 18 0 0 0-1.424.308q-.51.132-.765.378t-.378.739q-.114.483-.228 1.344a.4.4 0 0 1-.115.238.35.35 0 0 1-.246.087M8.385 5.15q-.202 0-.229-.21a17 17 0 0 0-.167-.836q-.07-.308-.228-.465-.15-.159-.466-.246a7 7 0 0 0-.87-.194q-.22-.035-.22-.228 0-.194.211-.229.563-.114.879-.202.315-.088.466-.246a.93.93 0 0 0 .228-.457q.07-.3.167-.826.027-.22.229-.22.194 0 .228.21.089.537.158.845.08.307.229.466.157.157.466.246.316.079.888.184.21.036.21.229 0 .194-.21.228-.562.105-.88.202-.316.088-.465.246-.15.159-.229.466-.078.3-.167.826-.035.211-.228.211'></path>
  </svg>
)
