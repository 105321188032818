export default (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='24'
    height='24'
    viewBox='0 0 24 24'
    fill='none'
    stroke='currentColor'
    stroke-width='2'
    stroke-linecap='round'
    stroke-linejoin='round'>
    <path d='m18 5-3-3H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2' />
    <path d='M8 18h1' />
    <path d='M18.4 9.6a2 2 0 1 1 3 3L17 17l-4 1 1-4Z' />
  </svg>
)
