import { ChatHookFn, ChatHookParams, ChatParams } from '.'
import { storeToRefs } from 'pinia'
import { userStore, llmParamsStore } from '@/store'
import { v4 as uuidv4 } from 'uuid'
import { Socket, io } from 'socket.io-client'
import { fetchChatAPI } from '@/api'
import { isUnDef } from '@/utils'

/**
 *@description 默认对话(随便聊聊)逻辑
 */
export const useDefaultChat: ChatHookFn = ({
  isAbort,
  message,
  activeTopic,
  activeAgent,
  updateTopic,
  scrollToBottom,
}: ChatParams) => {
  const { file, questions } = storeToRefs(llmParamsStore())

  const userstore = userStore()

  let controller = new AbortController()

  const activeIndex = ref<number | undefined>(undefined) //会话内容的索引, 默认是会后一条回答
  const isReGenerate = ref(false) // 是否重新生成

  const socketIOClientId = ref<string | null>(null)
  let socket: Socket | undefined = undefined
  const isStream = computed(() => {
    return activeAgent.value?.isStream === (1 || undefined)
  })
  watch(
    () => isStream.value,
    (val) => {
      val && connectToSocket()
    },
    { immediate: true },
  )
  /**
   *@description 连接socket，处理流式回答
   */

  function connectToSocket(cb?: any) {
    if (isStream.value) {
      socket = io(window.VITE_HTTP_PROXY)
      socket.on('error', (err) => {
        console.error('Socket.IO Manager Error:', err)
      })
      // 新增函数，设置 socket 事件监听
      setupSocketEvents(cb)
    } else {
      socketIOClientId.value = uuidv4()
    }
  }
  // 设置 socket 事件监听
  function setupSocketEvents(cb?: any) {
    socket?.on('connect', () => {
      socketIOClientId.value = socket!.id as string
      cb?.()
    })
    socket?.on('start', () => {
      isAbort.value = false
    })
    socket?.on('token', (data) => {
      if (!isAbort.value) {
        handleTokenData(data)
      }
    })

    socket?.on('sourceDocuments', (data) => {
      console.log(data)
    })

    socket?.on('end', () => {
      isAbort.value = true
      updateTopic()
    })
  }
  // 提取的处理 token 数据函数
  function handleTokenData(data: any) {
    const index =
      activeIndex.value !== undefined ? activeIndex.value : activeTopic.value!.messages.length - 1
    const messages = activeTopic.value!.messages[index]
    messages.content += data
    if (messages.content) {
      messages.status = 0
    }
    scrollToBottom()
  }
  /**
   * @description 发送(重试)会话信息
   * @param object {index: number} 重试回答是的信息所在索引
   */
  const sendMessage = async ({ index }: ChatHookParams = {}) => {
    if (socket?.disconnected) {
      connectToSocket(() => sendQuestion({ index }))
    } else {
      sendQuestion({ index })
    }
  }
  const sendQuestion = ({ index }: ChatHookParams = {}) => {
    if (!isStream.value || socketIOClientId.value) {
      isReGenerate.value = !isUnDef(index)
      controller = new AbortController()
      isAbort.value = false
      const len = activeTopic.value!.messages.length
      activeIndex.value = index ?? len - 1
      const messages = activeTopic.value!.messages[activeIndex.value]
      messages.content = ''
      messages.status = 2
      for (const item of activeTopic.value.messages) {
        if (item.recommendQ) {
          item.recommendQ.splice(0)
        }
      }
      try {
        postMessage({ messages })
      } finally {
        file.value = []
        questions.value = []
        scrollToBottom()
        updateTopic()
      }
    }
  }
  /**
   * @description 发送提问, 调用会话接口
   */
  const postMessage = ({ messages }: { messages: IChatMessage }) => {
    let msg = toRaw(message.value)
    message.value = ''
    // 重试操作, 寻找对应的提问
    if (isReGenerate.value) {
      messages.sourceDocuments = []
      const userMsg = activeTopic.value!.messages[activeIndex.value! - 1]
      msg = userMsg?.role === 'userMessage' ? userMsg?.content || '' : ''
    } else {
      messages.preQuestions = questions.value.join(',') || ''
    }
    // 获取files
    const files = file.value?.map((item) => item.file) || []

    const historyMsg = getHistoryMsg(activeTopic.value!, activeIndex.value)
    const signal = controller.signal
    const params = {
      sessionId: activeTopic.value!.id,
      userId: userstore.userInfo.userId,
      files: !isReGenerate.value ? files : [],
      preQuestions: messages.preQuestions,
      question: msg,
      socketIOClientId: socketIOClientId.value,
      history: historyMsg || [],
      chatFlowId: activeAgent.value?.agentId ?? '',
      overrideconfig: {
        token: userstore.userInfo.token,
      },
    }
    fetchChatAPI(params, signal)
      .then((res) => {
        if (res.code === 1) {
          handleStreamData(res.data, messages)
        } else {
          messages.content = res.msg || '未知错误'
          messages.status = 1
          isAbort.value = true
        }
      })
      .finally(() => {
        scrollToBottom()
        updateTopic()
      })
  }
  /**
   * @description 处理模型响应数据
   */
  const handleStreamData = (res: any, messages: IChatMessage) => {
    try {
      const data = JSON.parse(res.raw)
      const sourceDocuments = data.sourceDocuments?.filter((item: any) => typeof item !== 'number')
      const recommendQ = res.recommendQ
      messages.sourceDocuments = sourceDocuments || []
      messages.recommendQ = recommendQ || []
    } catch (error) {
      console.log(error)
    } finally {
      if (!isStream.value) {
        messages.content = res.text
        messages.status = 0
        isAbort.value = true
      }
    }
  }
  /**
   * @description 获取历史会话上下文
   * @param {ITopic} topicData
   */
  const getHistoryMsg = (topicData: ITopic, index: number | undefined) => {
    const data = index !== undefined ? topicData.messages.slice(0, index) : topicData.messages
    const historyMsg = data.map((item) => ({
      message: item.content,
      type: item.role,
    }))
    return historyMsg
  }

  /**
   * @description 停止回答
   */
  const stopStream = () => {
    isAbort.value = true
    controller?.abort()
    socket?.disconnect()

    const len = activeTopic.value!.messages.length
    activeIndex.value = activeIndex.value || len - 1
    const messages = activeTopic.value!.messages[activeIndex.value]
    messages.status = 0
    !messages.content && (messages.content = '已手动中止回答')
    scrollToBottom()
    updateTopic()
  }

  return { sendMessage, stopStream, name: 'chat' }
}
