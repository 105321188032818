<script setup lang="ts">
import { userStore, permissionStore } from '@/store'
import { useUserInfo } from '@/hooks/useUserInfo'
import { authDialog } from '@/components/LoginDialog'
import { useRefreshAgent } from '@/hooks/useOthers'
defineOptions({ name: 'AuthProvider' })

// 尝试从地址栏获取用户信息
const { getUserInfo } = useUserInfo()
const userInfo = getUserInfo()

// 存储用户信息
const userstore = userStore()
const permissionstore = permissionStore()
if (userInfo.userName) {
  userstore.setUserInfo(userInfo)
}
if (userstore.userInfo.token) {
  await permissionstore.getViewPermissions()
}

window.$useAuth = authDialog
const { showNotificationFn } = useRefreshAgent()
let socket1: WebSocket
watch(
  () => userstore.userInfo.userId,
  (userId) => {
    if (userId) {
      if (socket1 && socket1.readyState === WebSocket.OPEN) {
        socket1.close()
      }
      socket1 = new WebSocket(`${window.VITE_HTTP_PROXY}/websocket/skillCenter/${userId}`)

      // 当连接成功时触发
      socket1.onopen = function () {
        console.log('连接成功')
      }

      // 当接收到消息时触发
      socket1.onmessage = function (event) {
        const data = JSON.parse(event.data)
        if (data?.validate) {
          showNotificationFn()
        }
      }
      socket1.onclose = function () {
        console.log('连接关闭')
      }
    }
  },
  { immediate: true },
)
</script>

<template>
  <slot></slot>
</template>
