import { themeStore } from '@/store'
import { Hover } from '@/components/Hover'
import { Sun, Moon } from 'lucide-vue-next'

export const ThemeTrigger = defineComponent({
  name: 'ThemeTrigger',
  setup() {
    const themeStoreRef = themeStore()
    return () => (
      <Hover content='主题' onClick={themeStoreRef.switchTheme}>
        {themeStoreRef.isDark ? <Moon class='w-4 lg:w-5' /> : <Sun class='w-4 lg:w-6' />}
      </Hover>
    )
  },
})
