import { Popover, PopoverContent, PopoverTrigger } from '@/components/ui/popover'
import { Dialog, DialogContent } from '@/components/ui/dialog'
import { UserProfile } from '@/components/UserProfile'
import { Settings, LogOut } from 'lucide-vue-next'
import Avater from '@/assets/avater.png'
import { userStore, chatStore, permissionStore } from '@/store'
export const SignedIn = defineComponent({
  name: 'SignedIn',
  setup() {
    const perstore = permissionStore()
    const userstore = userStore()
    const chat = chatStore()
    const show = ref(false)
    const router = useRouter()
    const loginNow = () => {
      window.$useAuth()
    }
    const logoOut = () => {
      perstore.resetPermissions()
      userstore.resetState()
      chat.resetActiveAgent()
      // 跳转首页,并重载页面
      router.push({
        path: '/redirect/',
      })
    }
    return () => {
      return (
        <div class='flex items-center'>
          {userstore.userInfo.userId ? (
            <>
              {userstore.isWeChat ? (
                <div class='flex items-center'>
                  <n-avatar
                    round
                    size='small'
                    src={Avater}
                    fallback-src='https://07akioni.oss-cn-beijing.aliyuncs.com/07akioni.jpeg'
                  />
                  <div class={'ml-2'}>{userstore.userInfo.userName}</div>
                </div>
              ) : (
                <Popover>
                  <PopoverTrigger>
                    <div class='flex items-center'>
                      <n-avatar
                        round
                        size='small'
                        src={Avater}
                        fallback-src='https://07akioni.oss-cn-beijing.aliyuncs.com/07akioni.jpeg'
                      />
                      <div class={'ml-2'}>{userstore.userInfo.userName}</div>
                    </div>
                  </PopoverTrigger>
                  <PopoverContent>
                    <div class='flex flex-col'>
                      <div class='flex items-center py-3'>
                        <n-avatar
                          round
                          size='large'
                          src={Avater}
                          fallback-src='https://07akioni.oss-cn-beijing.aliyuncs.com/07akioni.jpeg'
                        />
                        <div class={'ml-4'}>
                          <div>{userstore.userInfo.userName}</div>
                          <div class={'text-xs'}>欢迎你</div>
                        </div>
                      </div>
                      <div
                        class='flex items-center py-3 border-t cursor-pointer rounded-sm text-neutral-700 dark:text-gray-100 hover:bg-neutral-100 dark:hover:bg-neutral-700'
                        onClick={() => (show.value = true)}>
                        <div class='w-10 flex justify-center items-center'>
                          <Settings class='w-4' />
                        </div>
                        <div class={'ml-2'}>用户管理</div>
                      </div>
                      <div
                        class='flex items-center  py-3 border-t cursor-pointer rounded-sm text-neutral-700 dark:text-gray-100 hover:bg-neutral-100 dark:hover:bg-neutral-700'
                        onClick={logoOut}>
                        <div class='w-10 flex justify-center items-center'>
                          <LogOut class='w-4' />
                        </div>
                        <div class={'ml-2'}>登出</div>
                      </div>
                    </div>
                  </PopoverContent>
                </Popover>
              )}
            </>
          ) : (
            <n-button class='flex-1' quaternary type='primary' onClick={loginNow}>
              立即登录
            </n-button>
          )}
          <Dialog v-model:open={show.value} modal={true}>
            <DialogContent class='max-w-[90vw]  overflow-hidden p-0 lg:max-w-[880px]'>
              <UserProfile />
            </DialogContent>
          </Dialog>
        </div>
      )
    }
  },
})
