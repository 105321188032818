import { useDefaultChat } from './useDefaultChat'
import { usePluginChat } from './usePluginChat'
import { useTableChat } from './useTableChat'
import { useDBChat } from './useDBChat'
import { useHumanChat } from './useHumanChat'
export type ChatParams = {
  /**
   * @description 是否停止
   */
  isAbort?: Ref<boolean>
  /**
   * @description 当前输入的内容
   */
  message: Ref<string>
  /**
   * @description 当前选中的topic
   */
  activeTopic: Ref<ITopic | undefined>
  /**
   * @description 当前选中的agent
   */
  activeAgent: Ref<ITopics | undefined>
  /**
   * @description 更新topic
   */
  updateTopic: () => void
  /**
   * @description 滚动到底部
   */
  scrollToBottom: () => void
}

export type ChatHook = {
  /**
   * @description 发送消息
   */
  sendMessage: (params?: ChatHookParams) => void
  /**
   * @description 停止流
   */
  stopStream: (params?: any) => void
  name: string
}
export type ChatHookFn = (params: ChatParams) => ChatHook

export type ChatHookParams = { index?: number; controller?: AbortController; loacl?: boolean }

export const useChatStrategies = ({ activeAgent }: Partial<ChatParams>) => {
  const chatTypeEnums: Partial<Record<string, ChatHookFn>> = {
    chat: useDefaultChat,
    'db-chat': useDBChat,
    'mask-chat': useDefaultChat,
    'table-chat': useTableChat,
    'human-chat': useHumanChat,
  }
  const chatHooks = computed(() => {
    if (activeAgent?.value?.isPlugin === 1) {
      return usePluginChat
    }
    return chatTypeEnums[activeAgent?.value?.type || 'chat'] || useDefaultChat
  })

  return {
    chatHooks,
  }
}
