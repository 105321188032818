<template>
  <n-config-provider
    :locale="zhCN"
    :theme-overrides="themeOverrides"
    :theme="themeStoreRef.theme"
    abstract
  >
    <NLoadingBarProvider>
      <NDialogProvider>
        <NNotificationProvider>
          <NMessageProvider :duration="4000" closable :max="6">
            <slot />
            <NaiveProviderContent />
          </NMessageProvider>
        </NNotificationProvider>
      </NDialogProvider>
    </NLoadingBarProvider>
    <NGlobalStyle />
  </n-config-provider>
</template>
<script setup lang="ts">
import {
  NConfigProvider,
  NDialogProvider,
  NLoadingBarProvider,
  NMessageProvider,
  NGlobalStyle,
  NNotificationProvider,
  useDialog,
  useLoadingBar,
  useMessage,
  useNotification,
  GlobalThemeOverrides,
  zhCN,
  useOsTheme,
} from 'naive-ui'
import { themeStore } from '@/store'

defineOptions({ name: 'AppProvider' })
const themeOverrides: GlobalThemeOverrides = {
  Button: {
    borderRadiusTiny: '4px',
    borderRadiusSmall: '4px',
    borderRadiusMedium: '4px',
    borderRadiusLarge: '4px',
  },
  Input: {
    borderRadius: '6px',
  },
  Select: {
    borderRadius: '6px',
  },
}

const themeStoreRef = themeStore()
// const toLine = (str: string): string => str.replace(/([A-Z])/g, '-$1').toLowerCase()

function registerNaiveTools() {
  window.$loadingBar = useLoadingBar()
  window.$dialog = useDialog()
  window.$message = useMessage()
  window.$notification = useNotification()
}
const NaiveProviderContent = defineComponent({
  name: 'NaiveProviderContent',
  setup() {
    registerNaiveTools()
  },
  render() {
    return null
  },
})
</script>
<!-- https://liubing.me/article/vue/naive-ui/naive-ui-custom-theme.html#%E9%80%82%E9%85%8D%E8%B7%9F%E9%9A%8F%E7%B3%BB%E7%BB%9F -->
