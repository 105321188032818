import localforage from 'localforage'
import { defineStore } from 'pinia'

/**
 * @description: 使用indexDB存储会话信息
 */

export const useChatDB = defineStore('chatIndexDB', () => {
  const db = localforage.createInstance({
    name: 'ZthChatIndexedDB',
  })
  const get = async <T>(key: string): Promise<T | null> => {
    const value = await db.getItem<T>(key)
    return value
  }
  const set = async <T>(key: string, value: any): Promise<T> => {
    const newValue = await db.setItem<T>(key, value)
    return newValue
  }
  const remove = async (key: string) => {
    const value = await db.removeItem(key)
    return value
  }
  const clear = async () => {
    const value = await db.clear()
    return value
  }
  return {
    get,
    set,
    remove,
    clear,
  }
})
