<script setup lang="ts">
import { getUserInfoByWechat } from '@/api'
import { userStore } from '@/store'
/**
 * @description: 微信服务号登录
 */
defineOptions({ name: 'WeChatProvider' })
const href = window.location.href
const searchParams = new URL(href).searchParams
// 判断是否使微信授权跳转链接
if (searchParams.has('code') && searchParams.has('state')) {
  const userstore = userStore()
  userstore.setIsWeChat(true)
  const code = searchParams.get('code')
  const state = searchParams.get('state')
  getUserInfoByWechat({ code, state })
    .then((res) => {
      if (res.code === 1) {
        const { user = {}, token, rt, headimgurl } = res.data
        userstore.setUserInfo({
          avatar: headimgurl ?? '',
          userName: user?.username,
          userId: user?.id,
          token: token,
          refreshToken: rt,
          department: user?.department || '微信用户',
        })
      } else {
        window.$message?.error(res.msg)
      }
    })
    .catch(() => {
      window.$message?.error('登录失败')
    })
}
function isWeChat() {
  const userAgent = navigator.userAgent.toLowerCase()
  return /micromessenger/i.test(userAgent)
}
</script>

<template>
  <slot></slot>
</template>
