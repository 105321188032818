import { LoginDialog } from './LoginDialog'
import { createApp } from 'vue'
import { userStore, chatStore, UserState, permissionStore } from '@/store'
const authDialog = (callback?: any, options = {}) => {
  const userstore = userStore()
  const permissionstore = permissionStore()
  if (userstore.userInfo.userId) {
    callback?.()
    return
  }
  const mountNode: HTMLDivElement = document.createElement('div')
  mountNode.setAttribute('data-id', 'id')
  document.body.appendChild(mountNode)
  const Instance = createApp(LoginDialog, {
    open: true,
    ...options,
    success: async (data: UserState) => {
      userstore.setUserInfo(data)
      await permissionstore.getViewPermissions()
      await chatStore().getDefaultAgent()
      chatStore().setActiveAgentByUserId()
      window['getSubscribeChatFlowHanlde']()
      callback?.()
    },
    close: () => {
      setTimeout(() => {
        document.body.removeChild(mountNode)
        Instance.unmount()
      }, 200)
    },
  })

  Instance.mount(mountNode)
}

export { authDialog }
