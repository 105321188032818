import { useResponsive } from '@/hooks/useResponsive'
import { ResizableHandle, ResizablePanel, ResizablePanelGroup } from '@/components/ui/resizable'

export const Desktop = defineComponent({
  name: 'Desktop',
  setup(_props, { slots }) {
    const panelRef = ref<InstanceType<typeof ResizablePanel>>()
    const isCollapsed = ref(false)
    function onCollapse() {
      isCollapsed.value = true
    }

    function onExpand() {
      isCollapsed.value = false
    }
    const { breakpoints } = useResponsive()

    watch(
      () => breakpoints.active().value,
      () => {
        nextTick(() => {
          const isDesktop = breakpoints.active().value === 'desktop'
          panelRef.value?.resize(isDesktop ? 17 : 28)
        })
      },
      { immediate: true },
    )
    return () => (
      <>
        {/* <button onClick={clickHandle}>{isCollapsed.value ? '展开' : '收起'}</button> */}
        <ResizablePanelGroup
          id='handle-demo-group-1'
          direction='horizontal'
          class='h-full w-full items-stretch'>
          <ResizablePanel
            ref={panelRef}
            id='handle-demo-panel-1'
            onExpand={onExpand}
            onCollapse={onCollapse}
            collapsible
            collapsedSize={0}
            minSize={16}
            maxSize={36}
            defaultSize={18}>
            {/* 插槽 */}
            {slots.left?.()}
          </ResizablePanel>
          <ResizableHandle id='handle-demo-handle-1' with-handle />
          <ResizablePanel id='handle-demo-panel-2'>
            {/* 插槽 */}
            {slots.right?.()}
          </ResizablePanel>
        </ResizablePanelGroup>
      </>
    )
  },
})
