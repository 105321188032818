import { useResponsive } from '@/hooks/useResponsive'
import { Desktop } from '@/Layout/Desktop'
import { Mobile } from '@/Layout/Mobile'
import { Sidebar } from '@/components/Sidebar'
import ChildView from '@/Layout/ChildView/index.vue'
export const Layout = defineComponent({
  setup() {
    const { isMobile } = useResponsive()
    return () => {
      return isMobile.value ? (
        <Mobile />
      ) : (
        <Desktop>
          {{
            left: () => <Sidebar />,
            right: () => <ChildView />,
          }}
        </Desktop>
      )
    }
  },
})
