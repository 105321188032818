import { Identifier } from '@/contants/identifiers'
import { permissionStore } from '@/store'
import { storeToRefs } from 'pinia'
export type FAuthProps = {
  value: Identifier
  arg?: string
}
export const Auth = (props: FAuthProps, { slots }) => {
  if (props.value === undefined) {
    return
  }
  const { identifierList } = storeToRefs(permissionStore())
  const isShow = identifierList.value.includes(props.value)
  return <>{isShow ? slots.default() : null}</>
}
