const getPluginTitle = (meta?: MetaData) => meta?.title
const getPluginDesc = (meta?: MetaData) => meta?.description

const getPluginTags = (meta?: MetaData) => meta?.tags
const getPluginAvatar = (meta?: MetaData) => meta?.avatar || '🧩'

const getPluginNameByIdentifier = (identifier: string) => {
  const [pluginName, pluginFunctionName] = identifier.split('__')
  return [pluginName, pluginFunctionName]
}

const isSettingSchemaNonEmpty = (schema?: PluginSchema) =>
  schema?.properties && Object.keys(schema.properties).length > 0

export {
  getPluginAvatar,
  getPluginDesc,
  getPluginTags,
  getPluginTitle,
  isSettingSchemaNonEmpty,
  getPluginNameByIdentifier,
}
