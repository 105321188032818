import { Socket, io } from 'socket.io-client'
import { fetchChatAPI, fetchIntentRecognition } from '@/api'
import { ChatHookParams, ChatParams, ChatHookFn } from '.'
import { v4 as uuidv4 } from 'uuid'
import { storeToRefs } from 'pinia'
import { userStore, llmParamsStore } from '@/store'
import { isUnDef } from '@/utils'

/**
 *@description 知识库会话逻辑
 */
export const useTableChat: ChatHookFn = ({
  isAbort,
  message,
  activeTopic,
  activeAgent,
  updateTopic,
  scrollToBottom,
}: ChatParams) => {
  const { file } = storeToRefs(llmParamsStore())

  const userstore = userStore()

  let controller = new AbortController()
  let controller1 = new AbortController()
  const activeIndex = ref<number | undefined>(undefined) //会话内容的索引, 默认是会后一条回答

  const isReGenerate = ref(false) // 是否重新生成

  const socketIOClientId = ref<string | null>(null)
  let socket: Socket | undefined = undefined
  const isStream = computed(() => {
    return activeAgent.value?.isStream === (1 || undefined)
  })
  watch(
    () => isStream.value,
    (val) => {
      val && connectToSocket()
    },
    { immediate: true },
  )
  /**
   *@description 连接socket，处理流式回答
   */
  function connectToSocket(cb?: any) {
    if (isStream.value) {
      socket = io(window.VITE_HTTP_PROXY)
      socket.on('error', (err) => {
        console.error('Socket.IO Manager Error:', err)
      })
      // 新增函数，设置 socket 事件监听
      setupSocketEvents(cb)
    } else {
      socketIOClientId.value = uuidv4()
    }
  }
  // 设置 socket 事件监听
  function setupSocketEvents(cb?: any) {
    socket?.on('connect', () => {
      socketIOClientId.value = socket!.id as string
      cb?.()
    })
    socket?.on('start', () => {
      isAbort.value = false
    })
    socket?.on('token', (data) => {
      if (!isAbort.value) {
        handleTokenData(data)
      }
    })

    socket?.on('sourceDocuments', (data) => {
      console.log(data)
    })

    socket?.on('end', () => {
      isAbort.value = true
      updateTopic()
    })
  }
  // 提取的处理 token 数据函数
  function handleTokenData(data: any) {
    const index =
      activeIndex.value !== undefined ? activeIndex.value : activeTopic.value!.messages.length - 1
    const messages = activeTopic.value!.messages[index]
    messages.content += data
    if (messages.content) {
      messages.status = 0
    }
    scrollToBottom()
  }

  /**
   * @description 发送(重试)会话信息，首先进行意图识别,如果识别为空则直模型回答
   * @param object {index: number} 重试回答是的信息所在索引
   */
  const sendMessage = async ({ index }: ChatHookParams = {}) => {
    isReGenerate.value = !isUnDef(index)
    controller = new AbortController()
    controller1 = new AbortController()
    isAbort.value = false

    const len = activeTopic.value!.messages.length
    activeIndex.value = index ?? len - 1
    const messages = activeTopic.value!.messages[activeIndex.value]
    messages.status = 2
    messages.content = ''

    let msg = toRaw(message.value)
    message.value = ''
    for (const item of activeTopic.value.messages) {
      if (item.recommendQ) {
        item.recommendQ.splice(0)
      }
    }
    // 重试操作, 寻找对应的提问
    if (isReGenerate.value) {
      messages.mediaMessage = []
      const userMsg = activeTopic.value!.messages[activeIndex.value - 1]
      msg = userMsg?.role === 'userMessage' ? userMsg?.content || '' : ''
    }
    const signal = controller1.signal
    fetchIntentRecognition<any>({ word: msg }, signal)
      .then((res) => {
        switch (res.code) {
          case 1: {
            messages.content = '查询到以下数据信息:'
            messages.mediaMessage = []
            res.data.forEach((item: IntentRecognitionResponse) => {
              const { agentUrl, url, chain, type } = item
              item.requestUrl = agentUrl ? `${agentUrl}${chain}` : `${url}${chain}`
              if (type === '1') {
                // 表格数据查询
                messages.mediaMessage?.push({
                  type: 'table',
                  data: item,
                })
              } else if (type === '2') {
                messages.mediaMessage?.push({
                  type: 'link',
                  data: item,
                })
              } else if (type === '3') {
                messages.mediaMessage?.push({
                  type: 'page',
                  data: item,
                })
              }
            })
            messages.status = 0
            isAbort.value = true
            scrollToBottom()
            setTimeout(() => {
              messages.isRead = true
              updateTopic()
            }, 1000)
            break
          }
          case -1:
            // 意图识别为空, 默认为AI对话
            if (socket?.disconnected) {
              connectToSocket(() => postMessage({ messages, msg }))
            } else {
              postMessage({ messages, msg })
            }
            break
          default:
            // 服务异常
            messages.status = 1
            messages.content = res.msg || '未知异常, 请重试！'
            isAbort.value = true
            scrollToBottom()
            updateTopic()
            break
        }
      })
      .finally(() => {
        file.value = []
      })
  }
  /**
   * @description 发送提问, 调用会话接口
   */
  const postMessage = async ({ messages, msg }: { messages: IChatMessage; msg: string }) => {
    // 获取files
    const files = file.value?.map((item) => item.file) || []
    const historyMsg = getHistoryMsg(activeTopic.value!, activeIndex.value)
    const signal = controller.signal
    const params = {
      sessionId: activeTopic.value!.id,
      userId: userstore.userInfo.userId,
      files: !isReGenerate.value ? files : [],
      question: msg,
      socketIOClientId: socketIOClientId.value,
      history: historyMsg || [],
      chatFlowId: activeAgent.value?.agentId ?? '',
      overrideconfig: {
        token: userstore.userInfo.token,
      },
    }
    fetchChatAPI(params, signal)
      .then((res) => {
        if (res.code === 1) {
          // 在当前回答存储提问问题(点赞点踩逻辑需要)
          messages.question = msg
          handleStreamData(res.data, messages)
        } else {
          messages.content = res.msg || '未知错误'
          messages.status = 1
          isAbort.value = true
        }
      })
      .finally(() => {
        scrollToBottom()
        updateTopic()
      })
  }
  /**
   * @description 处理模型响应数据
   */
  const handleStreamData = (res: any, messages: IChatMessage) => {
    try {
      const data = JSON.parse(res.raw)
      const sourceDocuments = data.sourceDocuments?.filter((item: any) => typeof item !== 'number')
      const recommendQ = res.recommendQ
      messages.sourceDocuments = sourceDocuments || []
      messages.recommendQ = recommendQ || []
    } catch (error) {
      console.log(error)
    } finally {
      if (!isStream.value) {
        messages.content = res.text
        messages.status = 0
        isAbort.value = true
      }
    }
  }

  /**
   * @description 获取历史会话上下文
   * @param {ITopic} topicData
   */
  const getHistoryMsg = (topicData: ITopic, index: number | undefined) => {
    const data = index !== undefined ? topicData.messages.slice(0, index) : topicData.messages
    const historyMsg = data.map((item) => ({
      message: item.content,
      type: item.role,
    }))
    return historyMsg
  }
  const stopStream = () => {
    isAbort.value = true
    controller?.abort()
    controller1.abort()
    socket?.disconnect()

    const len = activeTopic.value!.messages.length
    activeIndex.value = activeIndex.value || len - 1
    const messages = activeTopic.value!.messages[activeIndex.value]
    messages.status = 0
    !messages.content && (messages.content = '已手动中止回答')
  }
  return { sendMessage, stopStream, name: 'table-chat' }
}
