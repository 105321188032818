import { get, post } from '@/utils/request'
/**
 * @description 获取技能中心列表
 */
export function fetchSkillList<T = any>(params: {
  keyword: string
  isPublish: number | string
  userId: number | string
}) {
  return get<T>({
    url: '/llm/skillCenter/getChatFlowByUserId',
    data: params,
  })
}
/**
 * @description 获取技能分类
 */
export function fetchSkillCategory<T = any>() {
  return get<T>({
    url: `/llm/skillCenter/getChatFlowCategory`,
  })
}
/**
 * @description 用户订阅技能
 */
export function subscribeChatFlow<T = any>(data: {
  userId: number | string
  chatFlowIdList: Array<string>
}) {
  return post<T>({
    url: '/llm/skillCenter/subscribeChatFlow',
    data: data,
  })
}
/**
 * @description 用户取消订阅技能
 */
export function cancelSubscribeChatFlow<T = any>(data: {
  userId: number | string
  chatFlowIdList: Array<string>
}) {
  return post<T>({
    url: '/llm/skillCenter/cancelSubscribeChatFlow',
    data: data,
  })
}
