import { useBreakpoints } from '@vueuse/core'
export const useResponsive = () => {
  const breakpoints = useBreakpoints({
    mobile: 0, // optional
    tablet: 640,
    laptop: 1024,
    desktop: 1280,
  })
  return {
    breakpoints,
    isMobile: computed(() => unref(breakpoints.active()) === 'mobile'),
    isTablet: computed(() => unref(breakpoints.active()) === 'tablet'),
    isLaptop: computed(() => unref(breakpoints.active()) === 'laptop'),
    isDesktop: computed(() => unref(breakpoints.active()) === 'desktop'),
    smallerTablet: computed(() => unref(breakpoints.smaller('tablet'))),
    smallerLaptop: computed(() => unref(breakpoints.smaller('laptop'))),
    smallerDesktop: computed(() => unref(breakpoints.smaller('desktop'))),
  }
}
