import { cn } from '@/utils'
import { MessageCircleMore, ShoppingCart, Settings, FolderClosed } from 'lucide-vue-next'
export const MenuBar = defineComponent({
  name: 'MenuBar',
  setup() {
    const route = useRoute()
    const tabs = shallowRef([
      {
        icon: MessageCircleMore,
        title: '会话',
        path: '/chat',
      },
      {
        icon: ShoppingCart,
        title: '智能体',
        path: '/market',
      },
      {
        icon: FolderClosed,
        title: '知识库',
        path: '/files',
      },
      {
        icon: Settings,
        title: '设置',
        path: '/setting',
      },
    ])
    const router = useRouter()
    const routerGo = (path: string) => {
      window.$useAuth(() => {
        router.push(path)
      })
    }
    return () => (
      <div class='w-full h-full flex justify-around items-center'>
        {tabs.value.map((tab) => {
          const isActive = route.path === tab.path
          return (
            <div
              onClick={() => routerGo(tab.path)}
              class={cn('flex flex-col items-center select-none', isActive && 'text-theme-600')}>
              <tab.icon class={'w-6 h-6'}></tab.icon>

              <span class='text-xs'>{tab.title}</span>
            </div>
          )
        })}
      </div>
    )
  },
})
