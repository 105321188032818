import { Layout } from '@/Layout'
import { createRouter, createWebHashHistory, RouteRecordRaw, RouteMeta } from 'vue-router'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Layout',
    component: Layout,
    redirect: '/chat',
    meta: { title: '首页' },
    children: [
      {
        path: 'chat',
        name: 'Chat',
        component: () => import('@/pages/chat/index.vue'),
        meta: { title: '聊天', isCache: true },
      },

      {
        path: 'market',
        name: 'Market',
        component: () => import('@/pages/market/index.vue'),
        meta: { title: '市场' },
      },
      {
        path: 'setting',
        name: 'Setting',
        component: () => import('@/pages/setting/index.vue'),
        meta: { title: '设置' },
      },
      {
        path: 'files',
        name: 'Files',
        component: () => import('@/pages/files/index.vue'),
        meta: { title: '个人知识库', isCache: true },
      },
    ],
  },
  {
    path: '/chat-mobile',
    name: 'ChatMobile',
    component: () => import('@/pages/mobile-chat/index.vue'),
    meta: { title: '聊天', isCache: true },
  },
  {
    path: '/speech',
    name: 'Speech',
    component: () => import('@/pages/speech.vue'),
    meta: { title: '语音识别' },
  },
  {
    path: '/redirect/:path(.*)',
    component: () => import('@/pages/redirect/index.vue'),
    meta: {
      title: '重载',
    },
  },
]

const router = createRouter({
  history: createWebHashHistory(),
  routes,
  scrollBehavior(_to, from, savedPosition) {
    //  处理路由切换滚动行为
    return new Promise((resolve) => {
      if (savedPosition) {
        return savedPosition
      }
      if (from.meta.saveSrollTop) {
        const top: number = document.documentElement.scrollTop || document.body.scrollTop
        resolve({ left: 0, top })
      }
    })
  },
})
export default router
