export default (
  <svg
    class='icon'
    viewBox='0 0 24 24'
    version='1.1'
    xmlns='http://www.w3.org/2000/svg'
    p-id='5321'
    width='24'
    height='24'>
    <path
      d='M512 512m-512 0a512 512 0 1 0 1024 0 512 512 0 1 0-1024 0Z'
      fill='#5389F5'
      p-id='5322'></path>
    <path
      d='M512 192c-141.4 0-256 43-256 96v480c0 53 114.6 96 256 96s256-43 256-96V288c0-53-114.6-96-256-96z'
      fill='#A2BFFA'
      p-id='5323'></path>
    <path d='M256 288a256 96 0 1 0 512 0 256 96 0 1 0-512 0Z' fill='#F1F6FE' p-id='5324'></path>
    <path
      d='M512 384c-141.4 0-256-43-256-96v128c0 53 114.6 96 256 96s256-43 256-96V288c0 53-114.6 96-256 96z'
      fill='#C4D6FC'
      p-id='5325'></path>
    <path
      d='M512 560c-141.4 0-256-43-256-96v128c0 53 114.6 96 256 96s256-43 256-96V464c0 53-114.6 96-256 96z'
      fill='#C4D6FC'
      p-id='5326'></path>
    <path
      d='M512 736c-141.4 0-256-43-256-96v128c0 53 114.6 96 256 96s256-43 256-96V640c0 53-114.6 96-256 96z'
      fill='#C4D6FC'
      p-id='5327'></path>
  </svg>
)
