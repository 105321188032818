export function BSNote(msg: string) {
  const data = {
    msg: msg || '新消息',
    type: 'newMsg',
  }
  window.parent.postMessage(data, '*')
}
export function BSNoteClose(msg: string) {
  const data = {
    msg: msg || '新消息',
    type: 'newMsgClose',
  }
  window.parent.postMessage(data, '*')
}
