import { defineStore } from 'pinia'

export interface UserState {
  avatar?: string
  userId: string
  userName: string
  token: string
  refreshToken: string
  department: string
}

export const userStore = defineStore(
  'user',
  () => {
    const userInfo: UserState = reactive({
      userId: '',
      userName: '',
      token: '',
      refreshToken: '',
      department: '',
    })
    const isWeChat = ref(false)
    /**
     * @description 设置用户信息
     */
    const setUserInfo = (data: any) => {
      Object.assign(userInfo, data)
    }
    const setToken = (data: any) => {
      userInfo.token = data
    }
    const getState = () => {
      return userInfo
    }
    const resetState = () => {
      userInfo.userId = ''
      userInfo.userName = ''
      userInfo.token = ''
      userInfo.refreshToken = ''
      userInfo.auth = ''
      userInfo.department = ''
      userInfo.avatar = ''
    }
    const setIsWeChat = (data: boolean) => {
      isWeChat.value = data
    }
    return {
      setIsWeChat,
      isWeChat,
      userInfo,
      setUserInfo,
      getState,
      resetState,
      setToken,
    }
  },
  {
    persist: [
      {
        key: '_appChatSetting',
        storage: localStorage,
        paths: ['userInfo'],
      },
      {
        key: '_appRuntime',
        storage: sessionStorage,
        paths: ['isWeChat'],
      },
    ],
  },
)
