export default (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='24'
    height='24'
    viewBox='0 0 24 24'
    fill='transparent'
    stroke='currentColor'
    stroke-width='2'
    stroke-linecap='round'
    stroke-linejoin='round'>
    <rect width='18' height='18' x='3' y='3' rx='2'></rect>
    <path d='M15 3v18'></path>
    <path d='m10 15-3-3 3-3'></path>
  </svg>
)
