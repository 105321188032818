export default (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='24'
    height='20'
    viewBox='0 0 24 24'
    fill='none'
    stroke='currentColor'
    stroke-width='2'
    stroke-linecap='round'
    stroke-linejoin='round'
    class='lucide lucide-circle-stop'>
    <circle cx='12' cy='12' r='10' />
    <rect width='6' height='6' x='9' y='9' />
  </svg>
)
