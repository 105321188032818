import { onKeyStroke } from '@vueuse/core'
import { FormInst } from 'naive-ui'
import { login } from '@/api'
export const Login = defineComponent({
  name: 'Login',
  emits: ['success', 'error'],
  setup(_props, { emit }) {
    const formRef = ref<FormInst | null>(null)
    const formValue = reactive({
      name: '',
      password: '',
    })
    const rules = {
      name: {
        required: true,
        message: '请输入用户名',
        trigger: 'blur',
      },
      password: {
        required: true,
        message: '请输入密码',
        trigger: ['input', 'blur'],
      },
    }
    const loading = ref(false)
    const handleValidateClick = (e: MouseEvent | KeyboardEvent) => {
      e.preventDefault()
      formRef.value?.validate((errors: any) => {
        if (!errors) {
          loading.value = true
          login({ loginName: formValue.name, password: formValue.password })
            .then((res) => {
              if (res.code === 1) {
                const { user = {}, token, rt } = res.data
                emit('success', {
                  userName: user?.username,
                  userId: user?.id,
                  token: token,
                  refreshToken: rt,
                  department: user?.department,
                })
              } else {
                window.$message?.error(res.msg)
                emit('error', res.msg)
              }
            })
            .catch((err) => {
              window.$message?.error('服务错误,登录失败')
              emit('error', err)
            })
            .finally(() => {
              loading.value = false
            })
        } else {
          emit('error', errors)
        }
      })
    }
    onKeyStroke('Enter', (e) => {
      handleValidateClick(e)
    })
    return () => (
      <n-form ref={formRef} model={formValue} rules={rules}>
        <n-form-item label='用户名' path='name'>
          <n-input v-model:value={formValue.name} placeholder='输入用户名' />
        </n-form-item>
        <n-form-item label='密码' path='password'>
          <n-input v-model:value={formValue.password} type='password' placeholder='输入密码' />
        </n-form-item>
        <n-form-item show-feedback={false}>
          <div class='w-full flex justify-end'>
            <n-button
              loading={loading.value}
              attr-type='button'
              strong
              secondary
              type='primary'
              onClick={handleValidateClick}>
              立即登录
            </n-button>
          </div>
        </n-form-item>
      </n-form>
    )
  },
})
