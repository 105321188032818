import { useSupported } from '@vueuse/core'
/**
 * @description: 监听是否处于electron环境, 是的话就移除dom
 */
export const Electron = {
  mounted(el: HTMLElement) {
    const isElectron = useSupported(() => window.electron)
    if (isElectron.value) {
      el.remove()
    }
  },
}
