import { defineStore } from 'pinia'
import { UploadFileInfo } from 'naive-ui'

export const llmParamsStore = defineStore('llmParamsStore', () => {
  /**
   * @description 文件附件, 可根据文件内容进行上下文问答
   */
  const file = ref<UploadFileInfo[]>()

  /**
   * @description 图片附件
   */
  const imagesFiles = ref<UploadFileInfo[]>()

  /**
   * @description 合同审核问题
   */
  const questions = ref<string[]>([])

  return {
    file,
    imagesFiles,
    questions,
  }
})
