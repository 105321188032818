export const useUserInfo = () => {
  /**
   * @description 从window.location.origin获取userName , userId token 参数
   */
  const getUserInfo = () => {
    const { href } = window.location
    const userInfo = href.match(/userName=([^&]+)/)
    const userId = href.match(/userId=([^&]+)/)
    const token = href.match(/kftoken=([^&]+)/)
    return {
      userName: userInfo ? decodeURIComponent(userInfo[1]) : '',
      userId: userId ? userId[1] : '',
      token: token ? token[1] : '',
    }
  }
  const getQueryParams = () => {
    const params: any = {}
    const { href } = window.location
    const parser = new URL(href).href.split('?')[1]
    if (parser) {
      const query = parser.split('#')[0]
      const vars = query.split('&')
      for (let i = 0; i < vars.length; i++) {
        const pair = vars[i].split('=')
        params[pair[0]] = decodeURIComponent(pair[1])
      }
    }
    return params
  }
  return {
    getUserInfo,
    getQueryParams,
  }
}
