import { Share2 } from 'lucide-vue-next'
import { useShare } from '@vueuse/core'
import { Hover } from '../Hover'

export const Shared = defineComponent({
  name: 'Shared',
  setup() {
    const { share, isSupported } = useShare()
    function startShare() {
      isSupported.value &&
        share({
          title: '智能会话助手',
          text: '开启大脑🧠集群，激发思维火花。你的智能助理，在这里与你交流一切',
          url: location.href,
        })
    }
    return () => (
      <>
        {isSupported.value && (
          <Hover content='分享应用' onClick={startShare}>
            <Share2 class='w-5 h-5 lg:w-6 lg:h-6' />
          </Hover>
        )}
      </>
    )
  },
})
