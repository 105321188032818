import { DropActionBar } from '@/components/DropActionBar'
import Logo from '@/assets/logo.png'
import Deleted from '@/icons/Deleted'
import Pin from '@/icons/Pin'
import UnPin from '@/icons/UnPin'
import ArrowBigUp from '@/icons/ArrowBigUp'
import { useSwipe } from '@vueuse/core'
import { cn } from '@/utils'
import { userTalk } from '@/store'

export const AgentItem = defineComponent({
  name: 'AgentItem',
  props: {
    item: {
      type: Object as PropType<IAgent>,
      default: () => ({
        id: '',
        name: '',
        description: '',
        avatar: '',
      }),
    },
    showAction: {
      type: Boolean,
      default: true,
    },
  },
  emits: ['delete', 'rename', 'pin', 'click'],
  setup(props, { attrs, emit }) {
    // 用户消息api
    const uesrTalk = userTalk()
    const target = ref<HTMLElement | null>(null)
    const slider = ref<HTMLElement | null>(null)
    const sliderWidth = computed(() => slider.value?.offsetWidth)
    const left = ref('0')
    const opacity = ref(0)
    const { stop, lengthX } = useSwipe(target, {
      onSwipeStart: () => {
        console.log('onSwipeStart')
      },
      onSwipe: () => {
        if (lengthX.value > 0) {
          const length = Math.abs(lengthX.value)
          left.value = `${length > sliderWidth.value! ? sliderWidth.value! * -1 : length * -1} px`
        } else {
          left.value = '0'
          opacity.value = 0
        }
      },
      onSwipeEnd: () => {
        if (lengthX.value > 0 && Math.abs(lengthX.value) >= sliderWidth.value! / 2) {
          left.value = '-112px'
          opacity.value = 1
        } else {
          left.value = '0'
          opacity.value = 0
        }
      },
    })
    !props.showAction && stop()
    const { item } = toRefs(props)

    const DropActionBarRef = ref<HTMLElement | null>(null)
    const pinHandle = () => {
      emit('pin', item.value)
    }

    const list = shallowRef([
      {
        title: '置顶',
        icon: computed(() => (props.item.isPin ? UnPin : Pin)),
        action: () => pinHandle(),
      },
      {
        title: '移除',
        icon: Deleted,
        action: () => {
          window.$dialog?.error({
            closable: false,
            title: '警告, 将移除此技能',
            positiveText: '确定',
            negativeText: '取消',
            onPositiveClick: () => {
              emit('delete', item.value)
            },
          })
        },
      },
    ])
    const reset = (action: () => void) => {
      left.value = ''
      opacity.value = 0
      action()
    }
    return () => (
      <div class='h-16 relative w-full '>
        <div v-show={props.item.isPin} class='absolute right-1 top-2 z-50'>
          {/* <ArrowBigUp class={'w-4 h-4'} /> */}
          <div
            style='width: 0;
        height: 0;
        border-top: 6px solid transparent; 
        border-right: 6px solid transparent;
        border-left: 6px solid transparent;
        border-bottom: 6px solid #d4d4d4;transform: rotate(45deg)'></div>
        </div>

        <div
          ref={target}
          {...attrs}
          onClick={($event) => emit('click', { item: item.value, event: $event })}
          style={{
            left: left.value,
          }}
          class={cn(
            'transition-all ease-in-out duration-800',
            'w-full flex group select-none absolute inset-0 z-10',
            'justify-between p-4 pr-1 lg:p-3 lg:pr-2 cursor-pointer',
            'mt-1  rounded-md gap-2 items-center bg-white dark:bg-neutral-900',
            'hover:bg-gray-100 dark:hover:bg-neutral-800',
          )}>
          <div class='w-8 h-8 lg:w-10 lg:h-10 rounded-full overflow-hidden mr-1 pointer-events-none'>
            <img
              class='w-8 h-8 lg:w-10 lg:h-10 rounded-full overflow-hidden '
              src={props.item?.avatar || Logo}
              alt='logo'
            />
          </div>
          <div class='flex-1 overflow-hidden flex flex-col gap-1'>
            {props.item?.name === '在线专家坐席' ? (
              <n-badge value={uesrTalk.msgCount} max={99} offset={[-17, 6] as const}>
                <div class='text-sm lg:text-base text-ellipsis whitespace-nowrap overflow-hidden'>
                  {props.item?.name}
                </div>
              </n-badge>
            ) : (
              <div class='text-sm lg:text-base text-ellipsis whitespace-nowrap overflow-hidden'>
                {props.item?.name}
              </div>
            )}
            <div class='w-full text-xs text-gray-400 text-ellipsis whitespace-nowrap overflow-hidden'>
              {props.item?.description}
            </div>
          </div>
          {props.showAction && (
            <div class=' text-xs text-gray-400 hidden sm:group-hover:block'>
              <DropActionBar ref={DropActionBarRef} actions={list.value} />
            </div>
          )}
        </div>
        {props.showAction && (
          <div
            ref={slider}
            style={{
              opacity: opacity.value,
            }}
            class={cn(
              'sm:hidden h-12 absolute top-1 right-0 flex rounded-md overflow-hidden opacity-0 text-primary-foreground',
              'transition-all ease-in-out duration-800',
            )}>
            {list.value.map((item, index) => (
              <div
                key={index}
                onClick={() => reset(item.action)}
                class={cn(
                  'bg-primary w-14 flex items-center justify-center',
                  item.title === '移除' && 'bg-destructive dark:text-white',
                )}>
                {item.title}
              </div>
            ))}
          </div>
        )}
      </div>
    )
  },
})
