export default (
  <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32'>
    <path d='M24 21V9h-2v14h8v-2h-6z' fill='currentColor'></path>
    <path
      d='M18 9h-4a2 2 0 0 0-2 2v10a2 2 0 0 0 2 2h1v2a2 2 0 0 0 2 2h2v-2h-2v-2h1a2 2 0 0 0 2-2V11a2 2 0 0 0-2-2zm-4 12V11h4v10z'
      fill='currentColor'></path>
    <path
      d='M8 23H2v-2h6v-4H4a2 2 0 0 1-2-2v-4a2 2 0 0 1 2-2h6v2H4v4h4a2 2 0 0 1 2 2v4a2 2 0 0 1-2 2z'
      fill='currentColor'></path>
  </svg>
)
