import { RouterView } from 'vue-router'
import { FallBack } from '@/components/FallBack'
import AuthProvider from '@/providers/AuthProvider/index.vue'
import AppProvider from '@/providers/AppProvider/index.vue'
import { ChatProvider } from './ChatProvider'
import WeChatProvider from '@/providers/WeChatProvider/index.vue'

import { Suspense } from 'vue'
const App = defineComponent({
  setup() {
    return () => (
      <Suspense>
        {{
          default: () => (
            <WeChatProvider>
              <AuthProvider>
                <AppProvider>
                  <ChatProvider>
                    <RouterView />
                  </ChatProvider>
                </AppProvider>
              </AuthProvider>
            </WeChatProvider>
          ),
          fallback: () => <FallBack />,
        }}
      </Suspense>
    )
  },
})

export default App
