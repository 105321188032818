import { Sidebar } from '@/components/Sidebar'
import ChildView from '@/Layout/ChildView/index.vue'
import { MenuBar } from './MenuBar'

export const Mobile = defineComponent({
  name: 'Mobile',
  setup() {
    return () => (
      <div class='h-full w-full overflow-hidden flex flex-col'>
        <div class='flex-1 overflow-hidden overflow-y-auto'>
          <ChildView />
          {/* <Sidebar /> */}
        </div>
        <div class='w-full h-12 border-t border-neutral-100 dark:border-neutral-800'>
          <MenuBar />
        </div>
      </div>
    )
  },
})
