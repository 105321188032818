import { useWebSocket } from '@vueuse/core'
import { defineStore } from 'pinia'
const { PROD } = import.meta.env
export const userTalk = defineStore('userTalk', {
  state: () => ({
    isConnect: false,
    // 用户信息
    userInfo: {},
    socket: null,
    msgCount: 0,
  }),
  actions: {
    init(userInfo) {
      this.userInfo = userInfo
    },
    connect({ connect, message, error, disconnected }) {
      const that = this
      const wsurl = PROD
        ? `${window.VITE_HTTP_PROXY.replace(/^http:\/\//i, 'ws://')}/websocket/user/${this.userInfo.userId}`
        : `http://10.172.246.206:5002/websocket/user/${this.userInfo.userId}`
      // 连接socket
      this.socket = useWebSocket(wsurl, {
        onConnected(ws) {
          connect(ws)
          that.isConnect = true
        },
        onMessage(ws, event) {
          message(event.data)
        },
        onError(ws, event) {
          error()
        },
        onDisconnected(ws, event) {
          disconnected()
        },
        heartbeat: {
          message: `ping`,
          interval: 10000,
          pongTimeout: 10000,
        },
      })
    },
    //   用户下线
    user_off() {
      if (this.socket) {
        this.socket?.close()
        this.socket = null
        this.isConnect = false
      }
    },

    // user_on() {
    //   this.user_off()
    //   // 连接socket
    //   const that = this
    //   const wsurl = PROD
    //     ? `${window.VITE_HTTP_PROXY}/websocket/user/${this.userInfo.userId}`
    //     : `http://10.172.246.206:5002/websocket/user/${this.userInfo.userId}`
    //   const socket = useWebSocket(wsurl, {
    //     onConnected(ws) {
    //       that.socket = socket
    //       that.isConnect = true
    //     },
    //     onMessage(ws, event) {
    //       that.user_handleMessage(event.data)
    //     },
    //     onError(ws, event) {
    //       that.user_off()
    //     },
    //     onDisconnected(ws, event) {
    //       that.user_off()
    //     },
    //     heartbeat: {
    //       message: `ping`,
    //       interval: 10000,
    //       pongTimeout: 10000,
    //     },
    //   })
    // },
    // user_off() {
    //   if (this.socket) {
    //     this.socket?.close()
    //     this.socket = null
    //     this.isConnect = false
    //   }
    // },
    // async getUserMessage() {
    //   const res = await apiGet({
    //     url: PROD
    //       ? `/llm/socket/getMessageHistory?userId=${this.userInfo.userId}`
    //       : `http://10.172.246.206:5002/llm/socket/getMessageHistory?userId=${this.userInfo.userId}`,
    //   })
    //   const result: any = res.data || []
    //   const transRes = result.map((item) => {
    //     return {
    //       id: uuidv4(),
    //       role: this.transRole(item.type),
    //       content: item.content,
    //       date: new Date(item.createTime).toString(),
    //       status: 0,
    //       serverName: item.serverName || '',
    //     }
    //   })
    //   getMessage(transRes)
    // },
    // user_handleMessage(data) {
    //   if (data === 'pong') {
    //     // console.log('心跳')
    //     return
    //   }
    //   const msg = JSON.parse(data)
    //   const msgData = {
    //     id: uuidv4(),
    //     role: this.transRole(msg.type),
    //     content: msg.content,
    //     date: formatDate(new Date(msg.createTime), 'yyyy-mm-dd hh:mi:ss'),
    //     status: 0,
    //     serverName: msg.serverName,
    //   }
    //   handleMessage(msgData)
    // },
    // user_send(msg) {
    //   const data = {
    //     content: msg || '',
    //     type: 'user',
    //     userId: this.userInfo.userId,
    //     createTime: formatDate(new Date(), 'yyyy-mm-dd hh:mi:ss'),
    //   }
    //   const jsonStr = JSON.stringify(data)
    //   this.socket.send(jsonStr)
    // },
    // transRole(roleType) {
    //   let role = ''
    //   if (roleType === 'user') {
    //     role = 'userMessage'
    //   } else if (roleType === 'server') {
    //     role = 'apiMessage'
    //   } else {
    //     role = 'tipsMessage'
    //   }
    //   return role
    // },
    // // 设置是否处于客服状态
    // setIsKefu(payload) {
    //   this.isKefu = payload
    // },
    // // 设置用户信息
    // setUserInfo(payload) {
    //   this.userInfo = payload
    // },
    // //  设置用户socket
    // setSocket(socket) {
    //   this.socket = socket
    // },
    // // 添加消息talkList
    // addTalkList(data) {
    //   this.talkList.push(data)
    // },
    // // 清空state
    // clearState() {
    //   this.userInfo = {
    //     userId: '',
    //     userName: '',
    //   }
    //   // state.talkList = [
    //   //   {
    //   //     type: 'robot',
    //   //     content: '你好，我是智能助手，请问有什么可以帮到您的吗？',
    //   //     showzhuanjie: true
    //   //   }
    //   // ]
    //   this.socket = null
    //   this.isKefu = false
    //   this.talkList.push({
    //     content: '客服对话已结束转入AI...',
    //     type: 1,
    //   })
    // },
    // clearTalk() {
    //   this.talkList = [
    //     {
    //       type: 'robot',
    //       content: '你好，我是智能助手，请问有什么可以帮到您的吗？',
    //       showzhuanjie: true,
    //     },
    //   ]
    // },
    // // 用户上线
    // user_on(payload) {
    //   // 连接socket
    //   this.talkLast.push(this.talkList[this.talkList.length - 2])
    //   // console.log('user_on')
    //   this.addTalkList({
    //     content: '正在连线中，请等待...',
    //     type: 1,
    //   })
    //   const { url } = payload
    //   const { serverId } = payload
    //   const { userName } = payload
    //   const { serverName } = payload
    //   this.setUserInfo({
    //     userId: getLocalByAiUserInfo('userId') || '168',
    //     userName,
    //   })
    //   const wsurl = `${url}?serverId=${serverId}&userName=${userName}`
    //   const that = this
    //   const socket = useWebSocket(wsurl, {
    //     onConnected(ws) {
    //       that.addTalkList({
    //         content: `专家${serverName}为您服务！`,
    //         type: 1,
    //       })
    //       that.setIsKefu(true)
    //       if (that.talkLast.length) {
    //         const createTime = formatDate(new Date(), 'yyyy-mm-dd hh:mi:ss')
    //         const sendData = {
    //           userId: that.userInfo.userId || 168,
    //           userName: that.userInfo.userId || '',
    //           serverId,
    //           content: that.talkLast[0].content,
    //           type: 'server',
    //           createTime,
    //         }
    //         that.user_send(sendData)
    //         that.addTalkList(sendData)
    //         console.log(11111)
    //       }
    //       that.talkLast.splice(0)
    //     },
    //     onMessage(ws, event) {
    //       that.user_handleMessage(event.data)
    //       console.log('message', ws, event)
    //     },
    //     onError(ws, event) {
    //       console.log('error', ws, event)
    //     },
    //     onDisconnected(ws, event) {
    //       console.log('disconnect', ws, event)
    //       that.user_off()
    //     },
    //     // heartbeat: true
    //   })
    //   that.setSocket(socket)
    // },
    // // 用户接受消息
    // user_handleMessage(data) {
    //   // 处理收到的消息逻辑
    //   const jsonData = JSON.parse(data)
    //   console.log('用户端收到的消息', jsonData)
    //   if (jsonData.evaluate) {
    //     // 评价
    //     this.addTalkList({
    //       evaluate: jsonData.evaluate,
    //       content: jsonData.content,
    //     })
    //   } else if (jsonData.type === 'start' || jsonData.type === 'end') {
    //     this.addTalkList({
    //       type: 1,
    //       content: jsonData.content,
    //     })
    //     this.user_off()
    //   } else {
    //     this.addTalkList({
    //       type: jsonData.type === 'user' ? 1 : 2,
    //       content: jsonData.content,
    //     })
    //   }
    //   // commit mutation 或 dispatch其他action
    // },
    // // 用户发送消息
    // user_send(sendData) {
    //   console.log(sendData, 'sendDataDataData')
    //   const { socket } = this
    //   const jsonStr = JSON.stringify(sendData)
    //   socket.send(jsonStr)
    // },
    // // 其他接口
  },
})
