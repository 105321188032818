import { AxiosInstance, AxiosRequestConfig } from 'axios'
import { userStore } from '@/store'

interface PendingTask {
  config: AxiosRequestConfig
  resolve: (value: any) => void
}

let refreshing = false
const reTryReqeustList: PendingTask[] = []
export const refreshTokenInterceptors = async (http: AxiosInstance, error: any) => {
  if (!error.response) {
    return Promise.reject(error)
  }
  const userstore = userStore()
  const { data, config } = error.response
  if (data.code === 401 && !config.url.includes('/auth/refreshToken')) {
    if (refreshing) {
      return new Promise((resolve) => {
        reTryReqeustList.push({ config, resolve })
      })
    }
    refreshing = true
    try {
      const res = await refreshTokenFn(http)
      if (res.data.code === 401) {
        window.$message?.error('token失效，请重新登录')
        userstore.resetState()
        return Promise.reject(res.data)
      }
      const token = res.data.data
      userstore.setToken(token)
      config.headers!['Chattoken'] = token
      reTryReqeustList.forEach(({ config, resolve }) => {
        resolve(http.request(config))
      })
      reTryReqeustList.length = 0
      return http.request(config)
    } catch (error: any) {
      window.$message?.error(data.msg || '未知错误')
      return Promise.reject(error)
    } finally {
      refreshing = false
    }
  } else {
    return Promise.reject(error) // 否则，返回原始错误信息
  }
}
async function refreshTokenFn(http: AxiosInstance) {
  const userstore = userStore()
  const { refreshToken } = userstore.getState()
  const res = await http.get(`/auth/refreshToken`, {
    params: {
      refreshToken,
    },
  })
  return res
}
