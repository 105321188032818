import { App } from 'vue'
import { Click } from './vClick'
import { Electron } from './vElectron'

const directives = { Click, Electron }

export default {
  install(app: App) {
    Object.keys(directives).forEach((key) => {
      app.directive(key, directives[key as keyof typeof directives])
    })
  },
}
