/**
 * @description 默认技能，面具技能，无业务，由模型生成对话
 */
export const defaultAgent: IAgent = {
  id: '5b96c69cfa3f682110a952dc02100f40',
  // id: '072548369d2dac91c7d3178c899939c9',
  type: 'chat',
  name: '通用助手',
  description: '支持上传图片和文档、语音问答、联网实时搜索',
  tageDesc: '支持上传图片和文档、语音问答、联网实时搜索',
  avatar: '',
  isStream: 1,
  isFileUpload: 1,
  isPlugin: 1,
}

/**
 * @description 在线客服
 */
export const customerServiceAgent: IAgent = {
  id: '072548369d2dac91c7d3178c8911111',
  type: 'human-chat',
  name: '在线专家坐席',
  description: '在线专家坐席, 一对一解答',
  tageDesc: '',
  avatar: '',
  isStream: 2,
  isFileUpload: 2,
}
