import { EllipsisVertical } from 'lucide-vue-next'
export const DropActionBar = defineComponent({
  name: 'DropActionBar',
  props: {
    actions: {
      type: Array as PropType<{ title: string; icon: any; action: () => void }[]>,
      default: () => [],
    },
  },
  setup(props, { emit }) {
    const showPopover = ref(false)
    const attrs = useAttrs()

    const clickOutSide = () => {
      showPopover.value = false
    }

    const iconClick = (event: Event) => {
      // event.stopPropagation()
      showPopover.value = !showPopover.value
    }

    const myClick = (event: Event, item: any) => {
      // console.log(item, 'itemiemt')
      // event.stopPropagation()
      item.action()
      showPopover.value = false
    }

    return () => (
      <n-popover
        trigger='manual'
        show={showPopover.value}
        placement='bottom'
        raw
        {...attrs}
        on-clickoutside={clickOutSide}>
        {{
          trigger: () => (
            <span onClick={iconClick}>
              <EllipsisVertical class={'w-4 h-4'} />
            </span>
          ),
          default: () => (
            <ul class={'flex flex-col p-1 w-[138px] bg-white dark:bg-neutral-950 rounded-lg'}>
              {props.actions.map((item) => (
                <li
                  class={[
                    'flex items-center px-2 py-1 hover:bg-gray-100 dark:hover:bg-neutral-800 rounded cursor-pointer',
                    item.title === '移除' && 'hover:bg-red-100 text-red-500',
                  ]}
                  onClick={(event: Event) => {
                    myClick(event, item)
                  }}
                  key={item.title}>
                  <n-icon>{h(unref(item.icon))}</n-icon>
                  <span class='ml-2'>{item.title}</span>
                </li>
              ))}
            </ul>
          ),
        }}
      </n-popover>
    )
  },
})
