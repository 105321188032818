import { ChatHookFn, ChatHookParams, ChatParams } from '.'
import { v4 as uuidv4 } from 'uuid'
import { userStore, userTalk } from '@/store'
import { formatDate } from '@/utils'
import { getHumanChatHistory } from '@/api'
import { BSNote, BSNoteClose } from '@/utils/tools'
export const useHumanChat: ChatHookFn = ({
  isAbort,
  message,
  activeTopic,
  activeAgent,
  updateTopic,
  scrollToBottom,
}: ChatParams) => {
  BSNoteClose('新消息')
  // 用户信息
  const userstore = userStore()
  const userInfo = userstore.userInfo
  // 用户消息api
  const usertalk = userTalk()

  usertalk.init(userInfo)

  // 转换消息类型
  const transRole = (roleType: string): IChatRole => {
    switch (roleType) {
      case 'user':
        return 'userMessage'
      case 'server':
        return 'apiMessage'
      default:
        return 'tipsMessage'
    }
  }

  // 接收消息
  const userHandleMessage = (data) => {
    if (data === 'pong') {
      console.log('心跳')
      return
    }
    BSNote('新消息')
    if (activeAgent.value.type !== 'human-chat') {
      usertalk.msgCount++
      return
    }

    const msg = JSON.parse(data)
    const msgData: IChatMessage = {
      id: uuidv4(),
      role: transRole(msg.type),
      content: msg.content,
      date: formatDate(new Date(msg.createTime), 'yyyy-mm-dd hh:mi:ss'),
      status: 0,
      serverName: msg.serverName,
    }
    addTenMsg(activeTopic.value!.messages[activeTopic.value!.messages.length - 1].date)
    activeTopic.value!.messages.push(msgData)
    scrollToBottom()
    updateTopic()
    message.value = ''
  }

  // 下线
  const userOff = () => {
    if (usertalk.socket) {
      usertalk.socket?.close()
      usertalk.socket = null
      usertalk.isConnect = false
      usertalk.msgCount = 0
    }
  }

  // 上线
  const userOn = () => {
    userOff()
    usertalk.connect({
      connect: () => {},
      message: (data: any) => {
        userHandleMessage(data)
      },
      error: () => {
        userOff()
      },
      disconnected: () => {
        userOff()
      },
    })
  }

  // 获取用户消息
  const getUserMessage = async () => {
    try {
      const res = await getHumanChatHistory({ userId: userInfo.userId })
      usertalk.msgCount = 0
      const result = res.data || []
      const transRes: IChatMessage[] = result.map((item) => {
        return {
          id: uuidv4(),
          role: transRole(item.type),
          content: item.content,
          date: new Date(item.createTime).toString(),
          status: 0,
          serverName: item.serverName || '',
        }
      })
      activeTopic.value!.messages.splice(0)
      if (transRes.length === 0) {
        activeTopic.value!.messages.push({
          id: uuidv4(),
          role: 'apiMessage',
          content: '在线专家坐席, 一对一解答',
          date: new Date().toString(),
          status: 0,
        })
      }

      activeTopic.value!.messages.push(...transRes)
      setTimeout(() => {
        scrollToBottom()
      }, 0)
      updateTopic()
      // 连接socket
      if (!usertalk.isConnect) {
        userOn()
      }
    } catch (error) {
      console.error('获取历史用户消息时出错:', error)
      // 可以添加一个提示消息
    }
  }

  getUserMessage()
  // 发送消息
  const userSend = (msg: string) => {
    const data = {
      content: msg || '',
      type: 'user',
      userId: userInfo.userId,
      createTime: formatDate(new Date(), 'yyyy-mm-dd hh:mi:ss'),
    }
    const jsonStr = JSON.stringify(data)
    usertalk.socket?.send(jsonStr)
    usertalk.msgCount = 0
    BSNoteClose('新消息')
  }
  const sendMessage = async (_params: ChatHookParams = {}) => {
    const msg = message.value
    userSend(msg)
    // 移除AI占位消息
    activeTopic.value!.messages.pop()
    scrollToBottom()
    updateTopic()
    message.value = ''
    isAbort.value = true
  }
  const stopStream = () => {
    // TODO
  }

  // 超过十分钟，添加提示时间
  const addTenMsg = (time: string | number | Date) => {
    // 判断间隔十分钟
    if (time && Date.now() - new Date(time).getTime() > 10 * 1000 * 60) {
      activeTopic.value!.messages.push({
        id: uuidv4(),
        role: 'tipsMessage',
        content: formatDate(new Date(time), 'yyyy-mm-dd hh:mi:ss'),
        date: formatDate(new Date(time), 'yyyy-mm-dd hh:mi:ss'),
        status: 0,
      })
      scrollToBottom()
      updateTopic()
      message.value = ''
      isAbort.value = true
    }
  }

  return {
    sendMessage,
    stopStream,
    name: 'human-chat',
  }
}
