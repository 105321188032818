import { Identifier } from '@/contants/identifiers'
import { Directive, DirectiveBinding } from 'vue'
import { permissionStore } from '@/store'
import { storeToRefs } from 'pinia'
// 权限到按钮,v-auth:disabled="admin"

export const auth: Directive = {
  mounted(el: HTMLButtonElement, binding: DirectiveBinding<Identifier>) {
    const { value, arg } = binding
    if (value === undefined) {
      return
    }
    const { identifierList } = storeToRefs(permissionStore())
    if (!identifierList.value.includes(value)) {
      if (arg === 'disabled') {
        el.disabled = true
        el.style['disabled'] = 'disabled'
      } else {
        el.remove()
      }
    }
  },
}
export default auth
