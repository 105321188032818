import { Hover } from '@/components/Hover'
import { SignedIn } from '@/components/SignedIn'
import { StarShine } from '@/icons'
import { Auth } from '@/permission/Auth'
import { CloudUpload, Settings } from 'lucide-vue-next'
import { ThemeTrigger } from '@/components/ThemeTrigger'
import { Identifier } from '@/contants/identifiers'

export const BottomBar = defineComponent({
  name: 'BottomBar',
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
  setup(_props) {
    const router = useRouter()

    return () => (
      <>
        <Auth value={Identifier.AGENT_MARKET}>
          <div class='flex items-center border-t p-1'>
            <div
              onClick={() =>
                window.$useAuth(() => {
                  router.push('/market')
                })
              }
              title='发现AI技能'
              class='rounded-md w-full h-8 lg:h-10 font-bold cursor-pointer hover:bg-theme-100 dark:hover:bg-theme-900 flex items-center'>
              <StarShine class='ml-2' /> 智能体广场
            </div>
          </div>
        </Auth>
        <div class='flex items-center border-t pt-1 gap-3'>
          <div class='flex flex-1 items-center'>
            <SignedIn />
          </div>
          <div class={'flex items-center'}>
            {/* <Hover content='使用文档'>
              <Book class='w-4 lg:w-6' />
            </Hover> */}
            {/* <Hover
              content='个人知识库管理'
              onClick={() =>
                window.$useAuth(() => {
                  router.push('/files')
                })
              }>
              <CloudUpload class='w-4 lg:w-6' />
            </Hover> */}
            <Hover content='设置' onClick={() => window.$useAuth(() => router.push('/setting'))}>
              <Settings class='w-4 lg:w-6' />
            </Hover>
            <ThemeTrigger />
          </div>
        </div>
      </>
    )
  },
})
