export default (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='1em'
    height='1em'
    viewBox='0 0 24 24'
    fill='transparent'
    stroke='currentColor'
    stroke-width='2'
    stroke-linecap='round'
    stroke-linejoin='round'
    class='lucide lucide-trash '>
    <path d='M3 6h18'></path>
    <path d='M19 6v14c0 1-1 2-2 2H7c-1 0-2-1-2-2V6'></path>
    <path d='M8 6V4c0-1 1-2 2-2h4c1 0 2 1 2 2v2'></path>
  </svg>
)
